exports.components = {
  "component---src-components-templates-challenges-challenges-tsx": () => import("./../../../src/components/templates/Challenges/Challenges.tsx" /* webpackChunkName: "component---src-components-templates-challenges-challenges-tsx" */),
  "component---src-components-templates-location-locality-locality-tsx": () => import("./../../../src/components/templates/location/locality/Locality.tsx" /* webpackChunkName: "component---src-components-templates-location-locality-locality-tsx" */),
  "component---src-components-templates-location-location-lookup-location-lookup-tsx": () => import("./../../../src/components/templates/location/location-lookup/LocationLookup.tsx" /* webpackChunkName: "component---src-components-templates-location-location-lookup-location-lookup-tsx" */),
  "component---src-components-templates-location-location-tsx": () => import("./../../../src/components/templates/location/Location.tsx" /* webpackChunkName: "component---src-components-templates-location-location-tsx" */),
  "component---src-legacy-components-templates-donor-assessment-repeatable-tsx": () => import("./../../../src/legacy/components/templates/DonorAssessmentRepeatable.tsx" /* webpackChunkName: "component---src-legacy-components-templates-donor-assessment-repeatable-tsx" */),
  "component---src-legacy-components-templates-job-tsx": () => import("./../../../src/legacy/components/templates/Job.tsx" /* webpackChunkName: "component---src-legacy-components-templates-job-tsx" */),
  "component---src-legacy-components-templates-legal-tsx": () => import("./../../../src/legacy/components/templates/Legal.tsx" /* webpackChunkName: "component---src-legacy-components-templates-legal-tsx" */),
  "component---src-legacy-components-templates-our-mission-tsx": () => import("./../../../src/legacy/components/templates/OurMission.tsx" /* webpackChunkName: "component---src-legacy-components-templates-our-mission-tsx" */),
  "component---src-legacy-components-templates-page-tsx": () => import("./../../../src/legacy/components/templates/Page.tsx" /* webpackChunkName: "component---src-legacy-components-templates-page-tsx" */),
  "component---src-legacy-components-templates-post-tsx": () => import("./../../../src/legacy/components/templates/Post.tsx" /* webpackChunkName: "component---src-legacy-components-templates-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-deletion-index-tsx": () => import("./../../../src/pages/account-deletion/index.tsx" /* webpackChunkName: "component---src-pages-account-deletion-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-donor-assessment-success-index-tsx": () => import("./../../../src/pages/donor-assessment/success/index.tsx" /* webpackChunkName: "component---src-pages-donor-assessment-success-index-tsx" */),
  "component---src-pages-donor-assessment-success-outside-dma-tsx": () => import("./../../../src/pages/donor-assessment/success/outside-dma.tsx" /* webpackChunkName: "component---src-pages-donor-assessment-success-outside-dma-tsx" */),
  "component---src-pages-donor-assessment-success-over-sixty-four-tsx": () => import("./../../../src/pages/donor-assessment/success/over-sixty-four.tsx" /* webpackChunkName: "component---src-pages-donor-assessment-success-over-sixty-four-tsx" */),
  "component---src-pages-donor-assessment-success-success-eligible-tsx": () => import("./../../../src/pages/donor-assessment/success/success-eligible.tsx" /* webpackChunkName: "component---src-pages-donor-assessment-success-success-eligible-tsx" */),
  "component---src-pages-donor-assessment-success-success-uncomfortable-tsx": () => import("./../../../src/pages/donor-assessment/success/success-uncomfortable.tsx" /* webpackChunkName: "component---src-pages-donor-assessment-success-success-uncomfortable-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/NotFound.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */)
}

